// app/javascript/controllers/category_selector_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["skills"]

  connect() {
    // Optional: Überprüfen, ob Controller korrekt verbunden ist
    console.log("CategorySelector connected")
  }

  // Diese Methode wird ausgelöst, wenn die Kategorie geändert wird
  loadSkills(event) {
    const categoryId = event.target.value // ID der ausgewählten Kategorie
    if (!categoryId) return

    fetch(`/categories/${categoryId}/skills`)
      .then(response => response.json())
      .then(data => {
        // Alle vorhandenen Optionen im skillsTarget leeren
        this.skillsTarget.innerHTML = ""

        // Neue Optionen basierend auf den Daten hinzufügen
        data.forEach(skill => {
          let option = document.createElement("option")
          option.value = skill.id
          option.text = skill.name
          this.skillsTarget.appendChild(option)
        })
      })
  }
}